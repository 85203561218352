"use strict";

var _interopRequireDefault = require("D:/WorkProject/khpf_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("viewerjs/dist/viewer.css");

var _viewerjs = _interopRequireDefault(require("viewerjs"));

//
//
//
//
var _default2 = {
  props: {
    src: {
      type: String,
      default: ''
    },
    styles: {
      type: Object,
      default: function _default() {
        return {
          width: '100%',
          cursor: 'pointer'
        };
      }
    }
  },
  methods: {
    onShow: function onShow() {
      var image = new Image();
      image.src = this.src;
      var viewer = new _viewerjs.default(image, {
        hidden: function hidden() {
          viewer.destroy();
        }
      });
      viewer.show();
    }
  }
};
exports.default = _default2;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dict = require("@/api/system/dict");

var _default = {
  state: {
    dict: {},
    loading: false
  },
  getters: {},
  mutations: {
    setDict: function setDict(state, _ref) {
      var key = _ref.key,
          data = _ref.data;
      state.dict[key] = data;
    },
    loading: function loading(state, v) {
      state.loading = v;
    }
  },
  actions: {
    getDict: function getDict(_ref2, _ref3) {
      var state = _ref2.state,
          commit = _ref2.commit;
      var type = _ref3.type,
          _ref3$force = _ref3.force,
          force = _ref3$force === void 0 ? false : _ref3$force;
      return new Promise(function (resolve) {
        var key = "".concat(type);

        function checkLoading() {
          return new Promise(function (r) {
            if (force) {
              r(true);
            }

            var interval = setInterval(function () {
              if (!state.loading) {
                clearInterval(interval);
                interval = null;
                r(true);
              }
            }, 100);
          });
        }

        checkLoading().then(function () {
          var data = state.dict[key];

          if (undefined === data || force) {
            commit('loading', true);
            (0, _dict.getByType)(type).then(function (res) {
              commit('loading', false);
              var currentData = res;
              commit('setDict', {
                key: key,
                data: currentData
              });
              resolve(currentData);
            }).catch(function () {
              commit('loading', false);
            });
          } else {
            resolve(data);
          }
        });
      });
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("D:/WorkProject/khpf_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

var _defineProperty2 = _interopRequireDefault(require("D:/WorkProject/khpf_vue/node_modules/@babel/runtime/helpers/defineProperty.js"));

require("core-js/modules/es6.number.constructor");

var _vuex = require("vuex");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  props: {
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: ''
    },
    dictType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      content: ''
    };
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)(['getDict'])), {}, {
    hanldeShow: function hanldeShow() {
      var _this = this;

      this.getDict({
        type: this.dictType
      }).then(function (data) {
        var dict = data.find(function (e) {
          return String(e.value) === String(_this.value);
        });

        if (dict) {
          _this.content = dict.label;
        }
      });
    }
  }),
  render: function render() {
    var h = arguments[0];
    return h("span", [this.content]);
  },
  created: function created() {
    this.hanldeShow();
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("D:/WorkProject/khpf_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("D:/WorkProject/khpf_vue/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _vuex = require("vuex");

var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));

var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));

var _Doc = _interopRequireDefault(require("@/components/Doc"));

var _Screenfull = _interopRequireDefault(require("@/components/Screenfull"));

var _SizeSelect = _interopRequireDefault(require("@/components/SizeSelect"));

var _HeaderSearch = _interopRequireDefault(require("@/components/HeaderSearch"));

var _avatar = _interopRequireDefault(require("@/assets/images/avatar.png"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default,
    Screenfull: _Screenfull.default,
    SizeSelect: _SizeSelect.default,
    Search: _HeaderSearch.default,
    Doc: _Doc.default
  },
  data: function data() {
    return {
      Avatar: _avatar.default,
      dialogVisible: false
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['sidebar', 'device', 'user', 'baseApi'])), {}, {
    show: {
      get: function get() {
        return this.$store.state.settings.showSettings;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        });
      }
    }
  }),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    open: function open() {
      var _this = this;

      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.logout();
      });
    },
    logout: function logout() {
      this.$store.dispatch('LogOut').then(function () {
        location.reload();
      });
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("D:/WorkProject/khpf_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("D:/WorkProject/khpf_vue/node_modules/@babel/runtime/helpers/defineProperty.js"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _vuex = require("vuex");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: 'DictList',
  props: {
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: ''
    },
    dictType: {
      type: String,
      default: ''
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    bordered: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    var _this = this;

    return {
      options: {},
      currentValue: this.value,
      requestConfig: {
        url: function url() {
          return new Promise(function (resolve, reject) {
            _this.getDict({
              type: _this.dictType
            }).then(function (res) {
              resolve(res);
            }).catch(reject);
          });
        }
      }
    };
  },
  watch: {
    value: {
      handler: function handler(v) {
        if (JSON.stringify(v) !== JSON.stringify(this.currentValue)) {
          this.currentValue = v;
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted: function mounted() {
    var _this2 = this;

    var props = {};
    var filterProps = ['requestConfig', 'value', 'dictType'];
    Object.keys(this.$props).forEach(function (key) {
      if (!filterProps.includes(key)) {
        props[key] = _this2[key];
      }
    });
    this.options = props;
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)(['getDict'])), {}, {
    getDictData: function getDictData() {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        _this3.getDict({
          type: _this3.dictType
        }).then(function (res) {
          resolve();
        }).catch(reject);
      });
    },
    handleChange: function handleChange(v) {
      this.currentValue = v;
      this.$emit('input', v);
      this.$emit('change', v);
    }
  })
};
exports.default = _default;
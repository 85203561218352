"use strict";

var _interopRequireDefault = require("D:/WorkProject/khpf_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadVideoPath = exports.uploadPath = exports.baseUpload = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var uploadPath = '';
exports.uploadPath = uploadPath;
var uploadVideoPath = '';
exports.uploadVideoPath = uploadVideoPath;
var baseUpload = '';
exports.baseUpload = baseUpload;
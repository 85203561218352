"use strict";

var _interopRequireDefault = require("D:/WorkProject/khpf_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _storage = require("@/api/khpf/storage");

var _auth = require("@/utils/auth");

var _upload = require("@/utils/upload");

var _wangeditor = _interopRequireDefault(require("wangeditor"));

//
//
//
//
var _default = {
  uploadPath: _storage.uploadPath,
  name: 'WangEditor',
  props: {
    value: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data: function data() {
    return {
      currentValue: '',
      editor: null
    };
  },
  watch: {
    value: {
      handler: function handler(v) {
        if (v !== this.currentValue) {
          this.handleSetValue(v);
        }
      },
      deep: true,
      immediate: true
    }
  },
  beforeDestroy: function beforeDestroy() {
    var editor = this.editor;
    if (editor == null) return;
    editor.destroy();
  },
  mounted: function mounted() {
    var _this2 = this;

    var _this = this;

    var editor = new _wangeditor.default(this.$refs.wangEditor); // 配置 onchange 回调函数，将数据同步到 vue 中

    editor.config.onchange = function (newHtml) {
      _this2.currentValue = newHtml;

      _this2.$emit('input', newHtml);
    };

    editor.config.zIndex = 1000;
    editor.config.menus = ['head', 'bold', 'fontSize', 'fontName', 'italic', 'underline', 'strikeThrough', 'indent', 'lineHeight', 'foreColor', 'backColor', 'link', 'list', 'todo', 'justify', 'quote', 'emoticon', 'image', 'table', 'code', 'splitLine', 'undo', 'redo'];
    editor.config.uploadImgHeaders = {
      Authorization: (0, _auth.getToken)()
    }; // 文件上传

    editor.config.customUploadImg = function (files, insert) {
      // files 是 input 中选中的文件列表
      // insert 是获取图片 url 后，插入到编辑器的方法
      files.forEach(function (image) {
        (0, _upload.upload)(_storage.uploadPath, image).then(function (res) {
          var data = res.data;
          insert(data.url);
        });
      });
    };

    editor.create();
    this.editor = editor;
  },
  methods: {
    handleSetValue: function handleSetValue(v) {
      var _this3 = this;

      this.$nextTick(function () {
        _this3.editor.txt.html(v);
      });
    }
  }
};
exports.default = _default;
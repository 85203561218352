var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "l-custom-list",
    _vm._b(
      {
        attrs: { "request-config": _vm.requestConfig },
        on: { change: _vm.handleChange },
        model: {
          value: _vm.currentValue,
          callback: function ($$v) {
            _vm.currentValue = $$v
          },
          expression: "currentValue",
        },
      },
      "l-custom-list",
      _vm.options,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("D:/WorkProject/khpf_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("D:/WorkProject/khpf_vue/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _auth = require("@/utils/auth");

var _WangEditor = _interopRequireDefault(require("@/components/WangEditor"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var LTable = {
  setup: function setup($table) {
    $table.setup({
      grid: {
        border: true,
        stripe: true,
        showHeaderOverflow: true,
        showOverflow: true,
        highlightHoverRow: true,
        size: 'small',
        proxyConfig: {
          baseURL: '',
          props: {
            result: 'data',
            total: 'page.total'
          },
          headers: function headers() {
            return {
              'Authorization': (0, _auth.getToken)()
            };
          }
        },
        pagerConfig: {
          align: 'center',
          pageSize: 30,
          border: true,
          pagerCount: 7,
          pageSizes: [30, 50, 100],
          layouts: ['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']
        },
        toolbarConfig: {
          refresh: true,
          zoom: true,
          custom: true
        },
        rowConfig: {
          isHover: true
        }
      }
    });
  },
  mixin: function mixin($table) {
    $table.renderer.mixin({
      'dict-list': {
        renderItemContent: function renderItemContent(h, renderOpts, params) {
          var _renderOpts$props = renderOpts.props,
              props = _renderOpts$props === void 0 ? {} : _renderOpts$props,
              _renderOpts$events = renderOpts.events,
              events = _renderOpts$events === void 0 ? {} : _renderOpts$events;
          var property = params.property;
          var value = params.data[property];

          var defaultProps = _objectSpread(_objectSpread({}, {}), props);

          return [h('dict-list', {
            props: _objectSpread({
              value: value
            }, defaultProps),
            on: _objectSpread(_objectSpread({}, events), {}, {
              change: function change(value) {
                params.data[property] = value;
                params.$form.updateStatus({
                  property: property
                });

                if (events && events.change) {
                  events.change({
                    value: value,
                    data: params.data
                  });
                }
              }
            })
          })];
        }
      },
      'file-upload': {
        renderItemContent: function renderItemContent(h, renderOpts, params) {
          var _renderOpts$props2 = renderOpts.props,
              props = _renderOpts$props2 === void 0 ? {} : _renderOpts$props2,
              _renderOpts$events2 = renderOpts.events,
              events = _renderOpts$events2 === void 0 ? {} : _renderOpts$events2;
          var property = params.property;
          var value = params.data[property] || [];

          var defaultProps = _objectSpread(_objectSpread({}, {
            headers: {
              'Authorization': (0, _auth.getToken)()
            },
            // action: '/api/localStorage',
            action: '/api/uploadVideo',
            theme: 'file-input'
          }), props);

          if (!Array.isArray(value)) {
            value = [{
              id: value
            }];
          }

          if (value.length > 0) {
            value.forEach(function (item) {
              if (!item.url && item.id) {
                item.url = item.id;
              }

              item.name = item.url;
            });
          }

          return [h('l-upload', {
            props: _objectSpread({
              files: value
            }, defaultProps),
            on: _objectSpread(_objectSpread({}, events), {}, {
              change: function change(value) {
                var files = value.map(function (item) {
                  return {
                    // id: item.response.id
                    id: item.response.url
                  };
                });
                params.data[property] = files;
                params.$form.updateStatus({
                  property: property
                });

                if (events && events.change) {
                  events.change({
                    value: files,
                    uploadFile: value,
                    data: params.data
                  });
                }
              }
            })
          })];
        }
      },
      'image-upload': {
        renderItemContent: function renderItemContent(h, renderOpts, params) {
          var _renderOpts$props3 = renderOpts.props,
              props = _renderOpts$props3 === void 0 ? {} : _renderOpts$props3,
              _renderOpts$events3 = renderOpts.events,
              events = _renderOpts$events3 === void 0 ? {} : _renderOpts$events3;
          var property = params.property;
          var value = params.data[property] || [];

          var defaultProps = _objectSpread(_objectSpread({}, {
            headers: {
              'Authorization': (0, _auth.getToken)()
            },
            // action: '/api/localStorage/pictures',
            action: '/api/upload',
            theme: 'image',
            accept: 'image/*'
          }), props);

          if (!Array.isArray(value)) {
            value = [{
              id: value
            }];
          }

          if (value.length > 0) {
            value.forEach(function (item) {
              if (!item.url && item.id) {
                item.url = item.id;
              }
            });
          }

          return [h('l-upload', {
            props: _objectSpread({
              files: value
            }, defaultProps),
            on: _objectSpread(_objectSpread({}, events), {}, {
              preview: function preview(e) {
                if (events && events.preview) {
                  events.preview({
                    value: e,
                    data: params.data
                  });
                }
              },
              change: function change(value) {
                var files = value.map(function (item) {
                  return {
                    id: item.response.url // id: item.response.id

                  };
                });
                params.data[property] = files;

                if (events && events.change) {
                  events.change({
                    value: files,
                    uploadFile: value,
                    data: params.data
                  });
                }
              }
            })
          })];
        }
      },
      'editor': {
        renderItemContent: function renderItemContent(h, renderOpts, params) {
          var _renderOpts$props4 = renderOpts.props,
              props = _renderOpts$props4 === void 0 ? {} : _renderOpts$props4;
          var property = params.property;

          var defaultProps = _objectSpread(_objectSpread({}, {
            value: params.data[property] || ''
          }), props);

          return [h(_WangEditor.default, {
            props: defaultProps,
            on: {
              input: function input(v) {
                params.data[property] = v;
                params.$form.updateStatus({
                  property: property
                });
              }
            }
          })];
        }
      },
      'dict': {
        renderDefault: function renderDefault(h, renderOpts, params) {
          var _renderOpts$props5 = renderOpts.props,
              props = _renderOpts$props5 === void 0 ? {} : _renderOpts$props5,
              _renderOpts$dictType = renderOpts.dictType,
              dictType = _renderOpts$dictType === void 0 ? '' : _renderOpts$dictType;
          var property = params.column.property;
          var value = params.row[property];

          var defaultProps = _objectSpread(_objectSpread({}, {
            dictType: dictType
          }), props);

          return [h('dict-text', {
            props: _objectSpread({
              value: value
            }, defaultProps)
          })];
        }
      }
    });
  }
};
var _default = {
  init: function init($table) {
    LTable.setup($table);
    LTable.mixin($table);
  }
};
exports.default = _default;